import { useState } from 'react';

function ImageWithPlaceholder({ src }) {
  const [imageSrc, setImageSrc] = useState("https://picsum.photos/seed/picsum/200");

  const handleImageLoaded = () => {
    setImageSrc(src);
  };

  return (
    <img
      src={imageSrc}
      onLoad={handleImageLoaded}
    />
  );
}

export default ImageWithPlaceholder;
