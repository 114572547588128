import React from 'react';
import './ReshuffleButton.css'; // Your button specific styles
import './Game.css';

const ReshuffleButton = ({ onClick }) => {
  return (
    <button className="reshuffle-button noselect" onClick={onClick}>
      Re Shuffle
    </button>
  );
};

export default ReshuffleButton;
