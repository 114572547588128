import React from 'react';
import './ErrorOverlay.css'; // Your overlay specific styles

const ErrorOverlay = ({ message, onClick }) => {
  return (
    <div className="error-overlay" onClick={onClick}>
      <div className="error-message">{message}</div>
    </div>
  );
};

export default ErrorOverlay;
