import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login, { isLoggedIn } from './Login.tsx';
import Game from './Game.tsx';
import Match from './Match.tsx';

function App() {
    return (
        <Router>
          <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="/game" element={<Game/>} />
            <Route path="/match" element={<Match/>} />
          </Routes>
        </Router>
      );
}

export default App;

