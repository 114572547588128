import './Card.css'; // Assume your CSS for a card is here
import './Game.css';
import { memo } from 'react';
import ImageWithPlaceholder from './ImageWithPlaceholder';

const Card = memo(function Card({id, onClickCallback, isNameCard, name, url, isSelected, isFlipped, isFailed, isMatchMadness}) {
  const className = `noselect card ${isMatchMadness? 'cardmatch' : ''} ${isSelected ? 'selected': ''} ${isFlipped ? 'flipped cardBack': ''} ${isFailed ? 'failed': ''} ${!isNameCard? 'img': ''}`.trim();
  // console.log(`Card Render id:${id} isNameCard:${isNameCard} name:${name} selected: ${isSelected} flipped: ${isFlipped} failed: ${isFailed}`);


  function cardOnClick() {
    onClickCallback({type: 'click', id: id, isNameCard: isNameCard, name: name, url: url});
  }

  if (isFailed) {
    setTimeout(() => {
      onClickCallback({type: 'removeFailFromCard', id: id, isNameCard: isNameCard, name: name, url: url});
      }, 300);
  }

  return (
    <div key={id+name} className={className} id={id} onClick={!isFlipped? cardOnClick: undefined}>
      { 
        isFlipped? (<img className="cardImage" key="defaultimage" src="https://picsum.photos/seed/picsum/200"/>)
        :
        isNameCard? 
          (<span key={name}>{name}</span>) :
          (<ImageWithPlaceholder className="cardImage" src={url}/>)
      }
    </div>
  )
    });

export default Card;