import React, { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Login.css';

export function isLoggedIn() {
    const credentialJWT = Cookies.get('access_token');
    if (!credentialJWT) {
        return false;
    }

    const googleCreds = jwtDecode(credentialJWT) as any;
    if (googleCreds?.email_verified === false) {
        return false;
    }
    
    console.log("Logged In!");
    return true;
}

function Login() {
    const navigate = useNavigate();
    const [data, setData] = useState("");

    useEffect(() => {
        fetch('/api/data')
        .then((response) => response.json())
        .then((data) => setData(data.message))
        .catch((error) => console.error('Error fetching data:', error));
    }, []);

    return (
    <>
        <div className="logincontainer">
            <div className="loginheader">
                {data}
            </div>
        
            <div className="loginbuttoncontainer">
            <GoogleLogin
                onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                    Cookies.set('access_token', credentialResponse.credential, { expires: 7 }); // Expires in 7 days
                    const googleCreds = jwtDecode(credentialResponse.credential ?? "") as any;
                
                    console.log("Before navigate");
                    navigate('/game');
                    console.log("After navigate");
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
            </div>
            <div className="loginfooter">Created By Driz Production</div>
        </div>
    </>);
}

export default Login;