import './Card.css'; // Assume your CSS for a card is here
import './Game.css';

function DepartmentsDropdown({departments, onChangeCallback}) {

  return (
    <select className="dropdown" defaultValue="All" onChange={e => onChangeCallback(e.target.value)}>
      <option value="All">All</option>
      {departments.map((department) => (
        (<option key={department} value={department}>{department}</option>)
      ))}
    </select>
  );
};

export default DepartmentsDropdown;