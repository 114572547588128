import Card from './Card';

function Table({cards, onClickCallback}) {
  return (
    <div key="gametable" className='gametable'>
      {
        cards.map((card, cardIndex) => (
          <Card
            key={card.id}
            id={card.id}
            onClickCallback={onClickCallback}
            isNameCard={card.isNameCard}
            name={card.name}
            url={card.url}
            isSelected={card.isSelected}
            isFlipped={card.isFlipped}
            isFailed={card.isFailed}
            isMatchMadness={card.isMatchMadness}
          />
          )
        )
      }
    </div>
    );
};

export default Table;
