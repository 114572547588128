import React, { useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import ReshuffleButton from './ReshuffleButton';
import ErrorOverlay from './ErrorOverlay';
import DepartmentsDropdown from './DepartmentsDropdown';
import './Game.css'; // Your general styling, not specific to any component
import BottomBanner from './BottomBanner';

document.body.style.overflow = "hidden";

function Game() {
  console.log("Game component");
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState('All');
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [showError, setShowError] = useState(false);
  const initialState = {cards: [], selectedCards: [], flippedCardsCount: 0, sinceEmployeeId: 0};
  const [state, dispatch] = useReducer((state, action) => {
    let [cards, selectedCards, flippedCardsCount, sinceEmployeeId] = [state.cards, state.selectedCards, state.flippedCardsCount, state.sinceEmployeeId];
    switch (action.type) {
      case 'click':
        selectedCards = [...selectedCards, 
          {id: action.id,
           isNameCard: action.isNameCard,
           name: action.name
          }];
      
        if (selectedCards.length === 1) {
          let [firstCard] = selectedCards;
          cards = cards.map(card => {
            if (card.id === firstCard.id) {
              return { ...card, isSelected: true };
            } else {
              return card;
            }
          });
          return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};
        }
    
        if (selectedCards.length === 2) {
          let [firstCard, secondCard] = selectedCards;
          if (firstCard.id === secondCard.id) {
            console.log('The same card was clicked, do nothing and deselect');
            selectedCards = [];
            cards = cards.map(card => {
              if (card.id === firstCard.id) {
                return { ...card, isSelected: false };
              } else {
                return card;
              }
            });
            
            return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};
          }
    
          if (firstCard.isNameCard === secondCard.isNameCard) {
            console.log('The same type of card was clicked, make the last one be the only selected one and deselect the first one');
            selectedCards = [secondCard];
    
            cards = cards.map(card => {
              if (card.id === firstCard.id) {
                return { ...card, isSelected: false };
              } else if (card.id === secondCard.id) {
                return { ...card, isSelected: true };
              } else {
                return card;
              }
            });

            return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};
          }
    
          // The second card is different from the first one, select it and compare
          if (firstCard.name === secondCard.name) {
            console.log("Cards match!");
            
            flippedCardsCount += 2;
            selectedCards = [];

            cards = cards.map(card => {
              if (card.id === firstCard.id || card.id === secondCard.id) {
                return { ...card, isSelected: false, isFlipped: true};
              } else {
                return card;
              }
            });

            if (flippedCardsCount === 16) {
              setIsGameFinished(true);
            }

            return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};
          }
    
          console.log("Cards don't match!");
          selectedCards = [];
          cards = cards.map(card => {
            if (card.id === firstCard.id || card.id === secondCard.id) {
              return { ...card, isSelected: false, isFailed: true};
            } else {
              return card;
            }
          }); 
          
          return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};
        }

        return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};

      case 'removeFailFromCard':
        console.log("dispatch removeFail");
        cards = cards.map(card => {
          if (card.id === action.id) {
            return { ...card, isFailed: false};
          } else {
            return card;
          }
        });
        return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};

      case 'setCards':
        selectedCards = [];
        cards = action.namePhotoPairs;
        
        // This is not 0 because there might be cards that are already flipped
        // this can happen if the filter returned less than 8 pairs for example
        flippedCardsCount = cards.filter(card => card.isFlipped).length;
        return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};

      case 'setSinceEmployeeId':
        return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId: action.newSinceEmployeeId};

      default:
        return {cards:cards, selectedCards:selectedCards, flippedCardsCount:flippedCardsCount, sinceEmployeeId:sinceEmployeeId};
    }
  }, initialState);

  useEffect(() => {
    fetch('/api/departments')
    .then((response) => {
        if (response.status === 401) {
          navigate('/'); // Redirect to login page
          throw new Error(`Got response status: ${response.status}`)
        }
        return response.json()
      }
    )
    .then((data) => setDepartments(data.message))
    .catch((error) => console.error('Error fetching data:', error));

    getPairsByDepartment(department, state.sinceEmployeeId);
  }, []);

  function getPairsByDepartment(department, sinceEmployeeId) {
    fetch(`/api/pairs/${department}/${sinceEmployeeId}/8`)
    .then((response) => {
      if (response.status === 401) {
        navigate('/'); // Redirect to login page
        throw new Error(`Got response status: ${response.status}`)
      }
      return response.json()
    }
   )
    .then((data) => dispatch({type: "setCards", namePhotoPairs: data.message}))
    .catch((error) => console.error('Error fetching data:', error));
  }

  const handleDepartmentUpdate = (department: string) => {
    setDepartment(department);
    getPairsByDepartment(department, state.sinceEmployeeId);
    console.log(`try to update the state instead of dispatch`);
  };

  const reshuffleCards = () => {
    console.log("Reshuffling cards");
    getPairsByDepartment(department, state.sinceEmployeeId)
    setIsGameFinished(false);
  };

  const hideError = () => {
    setShowError(false);
  };

  return (
      <div className="app">
        <header className="app-header">
          <h1 className='noselect'>Paramemo</h1>
          {!isGameFinished && <ReshuffleButton onClick={reshuffleCards}/>}
          {!isGameFinished && 
          
            <input className='emplyeeId' type="number" onChange={event => {
              
              let newSinceEmployeeId = event.target.value; 
              if (event.target.value === "") {
                newSinceEmployeeId = "0";
              }
              dispatch({type: "setSinceEmployeeId", newSinceEmployeeId: newSinceEmployeeId})
            }}
            value={state.sinceEmployeeId}/>}
        </header>
        {showError && <ErrorOverlay onClick={hideError} message="An error has occurred!" />}
        {<DepartmentsDropdown departments={departments} onChangeCallback={handleDepartmentUpdate}></DepartmentsDropdown>}
        <div className='main-container'>
          {!isGameFinished && state.cards.length > 0 && 
            (
              <Table key="table" cards={state.cards} onClickCallback={dispatch}></Table>
            )
          }
          {isGameFinished && (
            <div>
              <div>You Win!</div>
              <ReshuffleButton onClick={reshuffleCards}/>
            </div>
          )}
          {/* <button onClick={() => {
            console.log(JSON.stringify(state));
          }}>Log State</button> */}
          <BottomBanner imgUrl={"https://t4.ftcdn.net/jpg/04/28/76/95/360_F_428769564_NB2T4JM9E2xsxFdXXwqW717HwgaZdpAq.jpg"}/>
        </div>
      </div>
  );
}
  

export default Game;
